@font-face {
  font-family: 'Lato-Normal';
  src: url(../static/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Lato-SemiBold';
  src: url(../static/fonts/latosemibold.ttf);
}

@font-face {
  font-family: 'Lato-Medium';
  src: url(../static/fonts/latomedium.ttf);
}

@font-face {
  font-family: 'Lato-Bold';
  src: url(../static/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: 'Lato-Black';
  src: url(../static/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url(../static/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(../static/fonts/Poppins-Regular.ttf);
}
